import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Tenant } from '../models/tenant.model';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TenantsLoaded } from '../store/tenant.actions';
import { UpdateTenantDto } from '../models/update-tenant.dto';
import { BrandConfig } from '../../branding/models/brand-config.model';
import { CreateTenantDto } from '../models/create-tenant.dto';
import * as moment from 'moment';
import { TenantRole } from '../../core/models/tenant-role.model';

@Injectable()
export class TenantService {
  private tenantApi = environment.tenantApi;
  private configApi = environment.configApi;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store<AppState>,
    private readonly http: HttpClient
  ) {}

  getTenants(): Observable<Tenant[]> {
    return this.http
      .get<Tenant[]>(this.tenantApi + `tenant/shared`, {
        headers: this.authService.getHeaders(),
      })
      .pipe(
        tap(tenants => {
          if (!!tenants && tenants?.length > 0) {
            this.store.dispatch(new TenantsLoaded({ tenants }));
          }
        })
      );
  }

  updateTenant(tenantId: string, body: UpdateTenantDto): Observable<boolean> {
    return this.http
      .put(this.tenantApi + `tenant/tenant-id/${tenantId}`, body, {
        headers: this.authService.getHeaders(),
      })
      .pipe(map(() => true));
  }

  checkIfDomainIsAvailable(domain: string): Observable<boolean> {
    return this.http
      .get<BrandConfig>(this.tenantApi + `tenant/branding/brand-code/${domain}`)
      .pipe(
        map(
          brand => brand.brandCode === 'sidedrawer' && domain !== 'sidedrawer'
        ),
        catchError((error: HttpErrorResponse) => of(error.status === 404))
      );
  }

  createTenant(dto: CreateTenantDto): Observable<Tenant> {
    return this.http.post<Tenant>(this.tenantApi + `tenant`, dto, {
      headers: this.authService.getHeaders(),
    });
  }

  async checkUploadImageAndGetUrl(
    ref: any,
    tenantId: string
  ): Promise<{ large: string; medium: string; small: string }> {
    let result: { large: string; medium: string; small: string } = {
      large: null,
      medium: null,
      small: null,
    };
    const i = ref.getStatus();
    if (i.hasChanged && !i.file) {
      return result;
    }

    if (i.hasChanged && i.file) {
      const image = {
        file: {
          item: i.file.f ? i.file.f : i.file.item,
        },
      };
      const url = await this.uploadImgToTenant(image, tenantId);
      result = { large: url, medium: url, small: url };
    } else {
      if (ref.files && ref.files.length > 0) {
        result = {
          large: ref.files[0].url,
          medium: ref.files[0].url,
          small: ref.files[0].url,
        };
      } else {
        result = null;
      }
    }
    return result;
  }

  async uploadImgToTenant(image, tenantId): Promise<string> {
    const formData = new FormData();
    formData.append('file', image.file.item);
    let fileNameTs = image.file.item.name;
    if (fileNameTs) {
      fileNameTs = fileNameTs.split('.')[0];
      fileNameTs = fileNameTs
        .replace(/[^a-zA-Z\d ]/g, '')
        .split(' ')
        .join('');
      fileNameTs = fileNameTs + moment(new Date()).format('_YYYYMMDDHHmmss');
      const result = await firstValueFrom(
        this.uploadTenantFiles(tenantId, formData, fileNameTs)
      );
      return result.fileUrl;
    }
    return null;
  }

  uploadTenantFiles(
    tenantId: string,
    formData: FormData,
    fileName: string
  ): Observable<{ fileUrl: string }> {
    return this.http.request<{ fileUrl: string }>(
      'POST',
      `${this.tenantApi}tenant/tenant-id/${tenantId}/files`,
      {
        body: formData,
        params: { fileName },
        headers: {
          Authorization: this.authService.getHeaders().Authorization,
        },
      }
    );
  }

  getTenantCustomRoles(): Observable<TenantRole[]> {
    return this.http.get<TenantRole[]>(
      this.configApi + 'tenant-roles/all-assignments'
    );
  }
}
